import {AxiosResponse} from "axios";
import {getDarInfoResponse} from "../interfaces/getDarInfo_interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import { PIRFiles, PIRReport } from "../interfaces/PIR.interface";
import { ALPRTimeout_Sec, ALPR_URL } from "./config.service";
import PlateSearch from "../interfaces/PlateSearch.interface";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";

export async function SetPIRReport(report: PIRReport): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReq.post('/api/PIR/SetPIR', report)
    return (res.data)
}

export async function SetPIRFile(fileObj: PIRFiles): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReq.post('/api/PIR/SetPIRFile', fileObj)
    return (res.data)
}

export async function SetPIRSupplement(supplementObj: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReq.post('/api/PIR/SetPIRSupplement', supplementObj)
    return (res.data)
}

export async function SearchPIR(searchObj: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/PIR/SearchPIR', searchObj)
    return (res.data)
}

export async function RunVINQuery(params: PlateSearch): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/Vin/Search', params, {
        baseURL: ALPR_URL
    })
    return (res.data)
}

export async function GetPIR(id:any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/PIR/GetPIR?IDs=' + id)
    return (res.data)
}

export async function GetPIRPDF(id: any, btreports: File[]): Promise<any> {
    const formData = new FormData();

    // Append ID as a form field
    formData.append("IDs", id);

    // Append each file in the array
    btreports.forEach((file, index) => {
        formData.append(`externalPDFs`, file); // `externalPDFs` matches the backend parameter
    });

    try {
        let res: AxiosResponse<Blob> = await AuthenticatedAPIReqSplash.post('/api/PIR/GetPIRPDF', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: "blob"
        });

        if (res && res.data) {
            const fileURL = URL.createObjectURL(res.data);
            window.open(fileURL);
        }
    } catch (error) {
        console.error("Error fetching PDF:", error);
    }
}


export async function RunGetPersonInfo(pdid: string) {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/PIR/GetPersonInfo?PDID=' + pdid)
    return (res.data)
}

export async function RunPersonSummaryPDFDataOnly(params: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.post('/api/Export/PersonSummaryPDF', params, { responseType: 'blob' })
    if (res != null) {
        return (res.data)
    }
}

export async function GetPIRPDFOnly(id: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/PIR/GetPIRPDF?IDs=' + id, { responseType: "blob" })
    return (res.data)
}

export async function getCCTVCamerasInRange(latitude:number, longitude:number, radius:string){
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/PIR/GetCCTVCamerasInRange?Latitude='+ latitude +'&Longitude='+longitude + '&Radius=' + radius)
    return (res.data)
}

export async function getIncidentsInRange(latitude: number, longitude: number, radius: string, inputDate: Date) {
    const formattedDate = inputDate.toISOString();

    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/PIR/GetIncidentsInRange?Latitude=' + latitude + '&Longitude=' + longitude + '&Radius=' + radius + '&InputDate=' + formattedDate)
    return (res.data)
}

export function GetGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}