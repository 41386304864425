export enum SITE { LOCAL, NJDEMO, NJUAT, NJPROD, DCDEMO, DCUAT, DCPROD, DCLOCAL, NYDEMO, NYUAT, NYPROD, NYLOCAL }

export enum TOOLS {
    ANKLE,
    ARREST,
    ARREST_ANALYSIS,
    CARJACKING_NOTIFICATION,
    CODIS_NOTIFICATION,
    DEVICE_LOCATION,
    DUPLICATE_PERSON,
    ECRAWL,
    EYE_ON_SCENE,
    FREQUENT_OFFENDER,
    GUN_OFFENDER_ENTRY,
    GVRTF_REFERRAL,
    HUMAN_TRAFFICKING,
    JAIL,
    LPR_COMMON_PLATE,
    LPR_SEARCH,
    NCIC_SEARCH,
    NIBIN,
    NJSTAD,
    NJTRACE,
    NOTIFICATION,
    OPEN_CASE,
    PAROLE,
    PERSON_NEARBY,
    POI,
    PRELIMINARY_REPORT,
    PRISONER_INFO,
    PROFESSIONAL_LICENSE,
    PROFILE_REPORT,
    SCOR_SEARCH,
    SCOR_VARIATION,
    SDW,
    SHOOTING_NOTIFICATION,
    SHOTSPOTTER,
    STATUTE_MAPPING,
    STOLEN_VEHICLE,
    THREAT_ASSESSMENT,
    TOWED_VEHICLE,
    TSC_FORM,
    UPDATE_PERSON_MAIN_RECORD,
    VIOLENT_OFFENDER,
    EVENT_REPORT,   //DC
    FIELD_CONTACT,  //DC
    MISSING_PERSON, //DC
    SEARCH_WARRANT_ASSESSMENT,  //DC
    SUPPLEMENTAL_REPORT,    //DC
    TRAFFIC_CRASH,  //DC
}

export enum PERSON_PANEL {
    ALPR,
    ANKLE,
    ARREST,
    ASSOCIATION_FIREARM, //DC
    ASSOCIATION_PEOPLE,
    BOLO,               //DC
    CADRMS,
    CODIS,
    ETICKED,
    ETRACE,
    FIELD_CONTACT,      //DC
    GUNOFFENDER,        //DC
    GUNSTAT,            //DC
    INFOCOP,
    JAIL,
    MISSING_PERSON,     //DC
    NIBIN,
    NJPOP,
    NJTRACE,
    PARKING,
    PAROLE,
    PERSON_ATTACHMENT,  //
    PRISION,
    PROFESSION,
    PROFILE_REPORT,
    REFERRAL,
    STOPDATA,           //DC
    SUPPLEMENTAL_REPORT,//DC
    TOWED_VEHICLE,
    TRAFFIC_CRASH,      //DC
    TSC,
    VIOLENT,
}

export enum SSO_TYPE { LOCAL_ONLY, DEPT_QUESTION, DEPT_HARDCODED, DEPT_USERID_PARSE }

export function GetSSOTypeForSite(site: SITE): SSO_TYPE {
    switch (site) {
        case SITE.DCPROD:
        case SITE.DCUAT:
        case SITE.DCDEMO:
        case SITE.DCLOCAL:
            return SSO_TYPE.DEPT_USERID_PARSE;
        case SITE.NJDEMO:
        case SITE.NJUAT:
        case SITE.NJPROD:
            return SSO_TYPE.LOCAL_ONLY;
        case SITE.LOCAL:
            return SSO_TYPE.LOCAL_ONLY;
        case SITE.NYDEMO:
        case SITE.NYPROD:
        case SITE.NYLOCAL:
            return SSO_TYPE.LOCAL_ONLY;
        default:
            return SSO_TYPE.LOCAL_ONLY;
    }
}
export function GetDefaultSSOForSite(site: SITE): string {
    switch (site) {
        case SITE.DCUAT:
        case SITE.DCDEMO:
        case SITE.DCLOCAL:
            return "DEMO_AZURE";
        case SITE.DCPROD:
            return "MPD";
        case SITE.NJDEMO:
        case SITE.NJUAT:
        case SITE.NJPROD:
        case SITE.NYDEMO:
        case SITE.NYPROD:
        case SITE.NYLOCAL:
        case SITE.LOCAL:
            return "";
        default:
            return ""
    }
}
export function IsToolEnabled(TOOL: TOOLS, ENABLED_TOOLS: TOOLS[]): boolean {
    return ENABLED_TOOLS.includes(TOOL);
}

export function IsPanelEnabled(PANEL: PERSON_PANEL, ENABLED_PANELS: PERSON_PANEL[]): boolean {
    return ENABLED_PANELS.includes(PANEL);
}

export function GetToolsForSite(site: SITE): TOOLS[] {
    let tools: TOOLS[] = [];
    switch (site) {
        case SITE.DCPROD:
        case SITE.DCUAT:
        case SITE.DCDEMO:
            tools = [
                //TOOLS.ANKLE,
                TOOLS.ARREST,
                TOOLS.ARREST_ANALYSIS,
                // TOOLS.CARJACKING_NOTIFICATION,
                //TOOLS.CODIS_NOTIFICATION,
                // TOOLS.DEVICE_LOCATION,
                TOOLS.DUPLICATE_PERSON,
                //TOOLS.ECRAWL,
                TOOLS.EYE_ON_SCENE,
                TOOLS.FREQUENT_OFFENDER,
                //TOOLS.GUN_OFFENDER_ENTRY,
                TOOLS.GVRTF_REFERRAL,
                // TOOLS.HUMAN_TRAFFICKING,
                TOOLS.JAIL,
                TOOLS.LPR_COMMON_PLATE,
                TOOLS.LPR_SEARCH,
                TOOLS.NCIC_SEARCH,
                TOOLS.NIBIN,
                // TOOLS.NOTIFICATION,
                TOOLS.SDW,
                //TOOLS.NJTRACE,
                TOOLS.OPEN_CASE,
                // TOOLS.PAROLE,
                TOOLS.PERSON_NEARBY,
                TOOLS.PRELIMINARY_REPORT,
                // TOOLS.PRISONER_INFO,
                // TOOLS.PROFESSIONAL_LICENSE,
                // TOOLS.PROFILE_REPORT,
                TOOLS.SCOR_SEARCH,
                TOOLS.SCOR_VARIATION,
                TOOLS.SHOOTING_NOTIFICATION,
                TOOLS.SHOTSPOTTER,
                TOOLS.STATUTE_MAPPING,
                TOOLS.STOLEN_VEHICLE,
                // TOOLS.THREAT_ASSESSMENT,
                TOOLS.TOWED_VEHICLE,
                // TOOLS.VIOLENT_OFFENDER,
                TOOLS.POI,
                //TOOLS.UPDATE_PERSON_MAIN_RECORD,
                TOOLS.MISSING_PERSON,
                //TOOLS.TRAFFIC_CRASH,
                //TOOLS.FIELD_CONTACT,
                //TOOLS.SUPPLEMENTAL_REPORT,
                TOOLS.TSC_FORM,
            ]
            break;
        case SITE.DCLOCAL:
            tools = [
                //TOOLS.ANKLE,
                TOOLS.ARREST,
                TOOLS.ARREST_ANALYSIS,
                // TOOLS.CARJACKING_NOTIFICATION,
                TOOLS.CODIS_NOTIFICATION,
                // TOOLS.DEVICE_LOCATION,
                TOOLS.DUPLICATE_PERSON,
                /*TOOLS.ECRAWL,*/
                // TOOLS.EYE_ON_SCENE,
                TOOLS.FREQUENT_OFFENDER,
                TOOLS.GUN_OFFENDER_ENTRY,
                TOOLS.GVRTF_REFERRAL,
                // TOOLS.HUMAN_TRAFFICKING,
                TOOLS.JAIL,
                TOOLS.LPR_COMMON_PLATE,
                TOOLS.LPR_SEARCH,
                TOOLS.NCIC_SEARCH,
                TOOLS.NIBIN,
                // TOOLS.NOTIFICATION,
                TOOLS.SDW,
                TOOLS.NJTRACE,
                TOOLS.OPEN_CASE,
                // TOOLS.PAROLE,
                TOOLS.PERSON_NEARBY,
                TOOLS.PRELIMINARY_REPORT,
                // TOOLS.PRISONER_INFO,
                // TOOLS.PROFESSIONAL_LICENSE,
                TOOLS.PROFILE_REPORT,
                TOOLS.SCOR_SEARCH,
                TOOLS.SCOR_VARIATION,
                TOOLS.SHOOTING_NOTIFICATION,
                TOOLS.SHOTSPOTTER,
                TOOLS.STATUTE_MAPPING,
                TOOLS.STOLEN_VEHICLE,
                // TOOLS.THREAT_ASSESSMENT,
                TOOLS.TOWED_VEHICLE,
                // TOOLS.VIOLENT_OFFENDER,
                TOOLS.POI,
                TOOLS.UPDATE_PERSON_MAIN_RECORD,
                TOOLS.MISSING_PERSON,
                TOOLS.TRAFFIC_CRASH,
                //TOOLS.FIELD_CONTACT,
                //TOOLS.SUPPLEMENTAL_REPORT,
                TOOLS.TSC_FORM,
                TOOLS.EVENT_REPORT,
                TOOLS.SEARCH_WARRANT_ASSESSMENT
            ]
            break;
        case SITE.NJUAT:
        case SITE.NJPROD:
            tools = [
                TOOLS.ARREST,
                TOOLS.ARREST_ANALYSIS,
                TOOLS.CARJACKING_NOTIFICATION,
                TOOLS.CODIS_NOTIFICATION,
                TOOLS.DEVICE_LOCATION,
                TOOLS.DUPLICATE_PERSON,
                //TOOLS.ECRAWL,
                TOOLS.EYE_ON_SCENE,
                TOOLS.FREQUENT_OFFENDER,
                TOOLS.GVRTF_REFERRAL,
                TOOLS.HUMAN_TRAFFICKING,
                TOOLS.JAIL,
                TOOLS.LPR_COMMON_PLATE,
                TOOLS.LPR_SEARCH,
                TOOLS.NCIC_SEARCH,
                TOOLS.NIBIN,
                TOOLS.NOTIFICATION,
                TOOLS.SDW,
                TOOLS.NJTRACE,
                TOOLS.OPEN_CASE,
                TOOLS.PAROLE,
                TOOLS.PERSON_NEARBY,
                TOOLS.PRISONER_INFO,
                TOOLS.PROFESSIONAL_LICENSE,
                TOOLS.PROFILE_REPORT,
                TOOLS.SCOR_SEARCH,
                TOOLS.SCOR_VARIATION,
                TOOLS.SHOOTING_NOTIFICATION,
                TOOLS.SHOTSPOTTER,
                TOOLS.STATUTE_MAPPING,
                TOOLS.STOLEN_VEHICLE,
                TOOLS.THREAT_ASSESSMENT,
                TOOLS.TOWED_VEHICLE,
                TOOLS.VIOLENT_OFFENDER,
                TOOLS.POI,
                TOOLS.UPDATE_PERSON_MAIN_RECORD,
                TOOLS.NJSTAD,
                TOOLS.TSC_FORM,
            ]
            break;
        case SITE.NJDEMO:
        case SITE.LOCAL:
            tools = [
                TOOLS.ARREST,
                TOOLS.ARREST_ANALYSIS,
                TOOLS.CARJACKING_NOTIFICATION,
                TOOLS.CODIS_NOTIFICATION,
                TOOLS.DEVICE_LOCATION,
                TOOLS.DUPLICATE_PERSON,
                TOOLS.ECRAWL,
                TOOLS.EYE_ON_SCENE,
                TOOLS.FREQUENT_OFFENDER,
                TOOLS.GVRTF_REFERRAL,
                TOOLS.HUMAN_TRAFFICKING,
                TOOLS.JAIL,
                TOOLS.LPR_COMMON_PLATE,
                TOOLS.LPR_SEARCH,
                TOOLS.NCIC_SEARCH,
                TOOLS.NIBIN,
                TOOLS.NOTIFICATION,
                TOOLS.SDW,
                TOOLS.NJTRACE,
                TOOLS.OPEN_CASE,
                TOOLS.PAROLE,
                TOOLS.PERSON_NEARBY,
                TOOLS.PRISONER_INFO,
                TOOLS.PROFESSIONAL_LICENSE,
                TOOLS.PROFILE_REPORT,
                TOOLS.TSC_FORM,
                TOOLS.SCOR_SEARCH,
                TOOLS.SCOR_VARIATION,
                TOOLS.SHOOTING_NOTIFICATION,
                TOOLS.SHOTSPOTTER,
                TOOLS.STATUTE_MAPPING,
                TOOLS.STOLEN_VEHICLE,
                TOOLS.THREAT_ASSESSMENT,
                TOOLS.TOWED_VEHICLE,
                TOOLS.VIOLENT_OFFENDER,
                TOOLS.POI,
                TOOLS.NJSTAD
            ]
            break;
        case SITE.NYPROD:
        case SITE.NYDEMO:
        case SITE.NYLOCAL:
            tools = [
                TOOLS.ARREST,
                TOOLS.ARREST_ANALYSIS,
                // TOOLS.CARJACKING_NOTIFICATION,
                //TOOLS.CODIS_NOTIFICATION,
                TOOLS.DEVICE_LOCATION,
                TOOLS.DUPLICATE_PERSON,
                //TOOLS.ECRAWL,
                // TOOLS.EYE_ON_SCENE,
                TOOLS.FREQUENT_OFFENDER,
                // TOOLS.GVRTF_REFERRAL,
                // TOOLS.HUMAN_TRAFFICKING,
                //TOOLS.JAIL,
                TOOLS.LPR_COMMON_PLATE,
                TOOLS.LPR_SEARCH,
                // TOOLS.NCIC_SEARCH,
                //TOOLS.NIBIN,
                // TOOLS.NOTIFICATION,
                //TOOLS.SDW,
                //TOOLS.NJTRACE,
                TOOLS.OPEN_CASE,
                // TOOLS.PAROLE,
                TOOLS.PERSON_NEARBY,
                //TOOLS.PRELIMINARY_REPORT,
                // TOOLS.PRISONER_INFO,
                // TOOLS.PROFESSIONAL_LICENSE,
                // TOOLS.PROFILE_REPORT,
                TOOLS.SCOR_SEARCH,
                TOOLS.SCOR_VARIATION,
                //TOOLS.SHOOTING_NOTIFICATION,
                //TOOLS.SHOTSPOTTER,
                TOOLS.STATUTE_MAPPING,
                TOOLS.STOLEN_VEHICLE,
                // TOOLS.THREAT_ASSESSMENT,
                // TOOLS.TOWED_VEHICLE,
                // TOOLS.VIOLENT_OFFENDER,
                //TOOLS.POI,
                //TOOLS.UPDATE_PERSON_MAIN_RECORD,
                //TOOLS.MISSING_PERSON,
                //TOOLS.TRAFFIC_CRASH,
                //TOOLS.FIELD_CONTACT,
                //TOOLS.SUPPLEMENTAL_REPORT
            ]
            break;
    }
    return tools;
}

export function GetPanelsForSite(site: SITE): PERSON_PANEL[] {
    let panels: PERSON_PANEL[] = [];
    switch (site) {
        case SITE.DCPROD:
        case SITE.DCUAT:
        case SITE.DCDEMO:
            panels = [
                PERSON_PANEL.ARREST,
                PERSON_PANEL.NJPOP,
                PERSON_PANEL.CODIS,
                //PERSON_PANEL.INFOCOP,
                PERSON_PANEL.ETICKED,
                PERSON_PANEL.CADRMS,
                PERSON_PANEL.STOPDATA,
                //PERSON_PANEL.PARKING,
                PERSON_PANEL.ETRACE,
                //PERSON_PANEL.NJTRACE,
                PERSON_PANEL.NIBIN,
                PERSON_PANEL.JAIL,
                //PERSON_PANEL.PRISION,
                //PERSON_PANEL.PAROLE,
                //PERSON_PANEL.VIOLENT,
                PERSON_PANEL.REFERRAL,
                //PERSON_PANEL.PROFILE_REPORT,
                //PERSON_PANEL.PROFESSION,
                PERSON_PANEL.ASSOCIATION_PEOPLE,
                PERSON_PANEL.ALPR,

                PERSON_PANEL.MISSING_PERSON, //DC
                PERSON_PANEL.TRAFFIC_CRASH, //DC
                PERSON_PANEL.FIELD_CONTACT, //DC
                PERSON_PANEL.SUPPLEMENTAL_REPORT, //DC
                PERSON_PANEL.GUNSTAT, //DC
                PERSON_PANEL.GUNOFFENDER, //DC
                PERSON_PANEL.BOLO, //DC
                //PERSON_PANEL.ASSOCIATION_FIREARM //DC
                PERSON_PANEL.PERSON_ATTACHMENT,
                PERSON_PANEL.TSC
            ]
            break;
        case SITE.DCLOCAL:
            panels = [
                PERSON_PANEL.ARREST,
                PERSON_PANEL.NJPOP,
                PERSON_PANEL.CODIS,
                //PERSON_PANEL.INFOCOP,
                PERSON_PANEL.ETICKED,
                PERSON_PANEL.CADRMS,
                PERSON_PANEL.STOPDATA,
                //PERSON_PANEL.PARKING,
                PERSON_PANEL.ETRACE,
                PERSON_PANEL.NJTRACE,
                PERSON_PANEL.NIBIN,
                PERSON_PANEL.JAIL,
                //PERSON_PANEL.PRISION,
                //PERSON_PANEL.PAROLE,
                //PERSON_PANEL.VIOLENT,
                PERSON_PANEL.REFERRAL,
                //PERSON_PANEL.PROFILE_REPORT,
                //PERSON_PANEL.PROFESSION,
                PERSON_PANEL.ASSOCIATION_PEOPLE,
                PERSON_PANEL.ALPR,

                PERSON_PANEL.MISSING_PERSON, //DC
                PERSON_PANEL.TRAFFIC_CRASH, //DC
                PERSON_PANEL.FIELD_CONTACT, //DC
                PERSON_PANEL.SUPPLEMENTAL_REPORT, //DC
                PERSON_PANEL.GUNSTAT, //DC
                PERSON_PANEL.GUNOFFENDER, //DC
                PERSON_PANEL.BOLO, //DC
                //PERSON_PANEL.ASSOCIATION_FIREARM //DC
                PERSON_PANEL.PERSON_ATTACHMENT,
                PERSON_PANEL.TSC,
                PERSON_PANEL.TOWED_VEHICLE
            ]
            break;
        case SITE.NJDEMO:
        case SITE.NJUAT:
        case SITE.NJPROD:
        case SITE.LOCAL:
            panels = [
                PERSON_PANEL.ARREST,
                PERSON_PANEL.NJPOP,
                PERSON_PANEL.CODIS,
                PERSON_PANEL.INFOCOP,
                PERSON_PANEL.ETICKED,
                PERSON_PANEL.CADRMS,
                PERSON_PANEL.PARKING,
                PERSON_PANEL.ETRACE,
                PERSON_PANEL.NJTRACE,
                PERSON_PANEL.NIBIN,
                PERSON_PANEL.JAIL,
                PERSON_PANEL.PRISION,
                PERSON_PANEL.PAROLE,
                PERSON_PANEL.VIOLENT,
                PERSON_PANEL.REFERRAL,
                PERSON_PANEL.PROFILE_REPORT,
                PERSON_PANEL.PROFESSION,
                PERSON_PANEL.ASSOCIATION_PEOPLE,
                PERSON_PANEL.ALPR,
                PERSON_PANEL.TSC,
                PERSON_PANEL.TOWED_VEHICLE
            ]
            break;
        case SITE.NYLOCAL:
        case SITE.NYDEMO:
        case SITE.NYPROD:
            panels = [
                PERSON_PANEL.ARREST,
                //PERSON_PANEL.NJPOP,
                //PERSON_PANEL.CODIS,
                //PERSON_PANEL.INFOCOP,
                PERSON_PANEL.ETICKED,
                PERSON_PANEL.CADRMS,
                //PERSON_PANEL.PARKING,
                //PERSON_PANEL.ETRACE,
                //PERSON_PANEL.NJTRACE,
                //PERSON_PANEL.NIBIN,
                //PERSON_PANEL.JAIL,
                //PERSON_PANEL.PRISION,
                //PERSON_PANEL.PAROLE,
                //PERSON_PANEL.VIOLENT,
                //PERSON_PANEL.REFERRAL,
                //PERSON_PANEL.PROFILE_REPORT,
                //PERSON_PANEL.PROFESSION,
                PERSON_PANEL.ASSOCIATION_PEOPLE,
                PERSON_PANEL.ALPR,
            ]
            break;
    }
    return panels;
}

export function GetDefaultStateForSite(site: SITE): string {
    switch (site) {
        case SITE.DCDEMO:
        case SITE.DCUAT:
        case SITE.DCPROD:
        case SITE.DCLOCAL:
            return "DC";
        case SITE.NJDEMO:
        case SITE.NJUAT:
        case SITE.NJPROD:
        case SITE.LOCAL:
            return "NJ";
        case SITE.NYDEMO:
        case SITE.NYPROD:
        case SITE.NYLOCAL:
            return "NY";
        default:
            return "NJ"
    }
}

export function getCenterForSite(site: SITE): any {
    switch (site) {
        case SITE.DCDEMO:
        case SITE.DCUAT:
        case SITE.DCPROD:
        case SITE.DCLOCAL:
            return { lat: 38.9072, lng: -77.0369 };
        case SITE.NJDEMO:
        case SITE.NJUAT:
        case SITE.NJPROD:
        case SITE.LOCAL:
            return { lat: 40.844600, lng: -74.108270 };
        case SITE.NYDEMO:
        case SITE.NYPROD:
        case SITE.NYLOCAL:
            return { lat: 42.662901, lng: -73.784245 };
    }
}

export function GetStringNameForSite(site: SITE, keyword: string): string {
    switch (keyword.toUpperCase()) {
        case "ALPRSERVER":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "ALPR Areas";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "ALPR Areas";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "ALPR Areas";
                default:
                    return ""
            }
        case "AGENCY CASE #":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "CCN #";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Agency Case #";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Agency Case #";
                default:
                    return ""
            }
        case "CASE #":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "CCN #";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Case #";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Case #";
                default:
                    return ""
            }
        case "CASE NUMBER":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "CCN #";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Case Number";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Case Number";
                default:
                    return ""
            }
        case "CITY":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "PSA/City";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "City";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "City";
                default:
                    return "";
            }
        case "CITIES":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "PSAs/Cities";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Cities";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Cities";
                default:
                    return "";
            }
        case "COUNTY":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "District/County";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "County";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "County";
                default:
                    return "";
            }
        case "COUNTIES":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Districts/Counties";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Counties";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Counties";
                default:
                    return "";
            }
        case "ECDR":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Other";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "eCDR";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "eCDR";
                default:
                    return "";
            }
        case "STATUTES":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Offenses";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Statutes";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Statutes";
                default:
                    return "";
            }
        case "STATUTE":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Offense";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Statute";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Statute";
                default:
                    return "";
            }
        case "NCIC":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "WALES";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "NCIC";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "NCIC";
                default:
                    return "NCIC";
            }
        case "BEAT":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "PSA";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Beat";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Beat";
                default:
                    return "";
            }
        case "SCOR":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Position";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "SCOR";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Position";
                default:
                    return ""
            }
        case "SCOR_TBL_PERSON":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Position";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "SCOR Rank";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Position";
                default:
                    return ""
            }
        case "NJTRACE":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "DCTRACE";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "NJTRACE";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "NYTRACE";
                default:
                    return ""
            }
        case "NJGUNTRACE":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "DCGunTrace";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "NJGunTrace";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "NYGunTrace";
                default:
                    return ""
            }
        case "NJPOP":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "DC Gun Violence";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "NJPOP";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "NY Gun Violence";
                default:
                    return ""
            }
        case "NJ POP":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "DC Gun Violence";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "NJPOP";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "NY Gun Violence";
                default:
                    return ""
            }
        case "SCOR_TBL":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Position";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "SCOR Ranking";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Position";
                default:
                    return ""
            }
        case "SCOR DETAILS":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Summary";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "SCOR";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Summary";
                default:
                    return ""
            }
        case "SCOR_DETAILS_TBL":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Total";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "SCOR";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Total";
                default:
                    return ""
            }
        case "NAV_TOOL_OPENCASE":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Offense/Incident";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Open Case Incident";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Offense/Incident";
                default:
                    return ""
            }
        case "BTN_TOOL_OPENCASE":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Offense";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "Open Case";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Offense";
                default:
                    return ""
            }
        case "CAD/RMS":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "Offense/Incident";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "CAD/RMS";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "Offense/Incident";
                default:
                    return ""
            }
        case "EXCEL_EXPORT_OPENCASE":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "OffenseIncident";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "OpenCase";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "OffenseIncident";
                default:
                    return ""
            }
        case "SBI #":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "PDID #";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "SBI #";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "NYSID";
                default:
                    return ""
            }
        case "SBI":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "PDID";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "SBI";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "NYSID";
                default:
                    return ""
            }
        case "JURISDICTION":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "FACILITY";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "JURISDICTION";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "JURISDICTION";
                default:
                    return ""
            }
        case "SCOR_PIVOT":
            switch (site) {
                case SITE.DCDEMO:
                case SITE.DCUAT:
                case SITE.DCPROD:
                case SITE.DCLOCAL:
                    return "POSITION";
                case SITE.NJDEMO:
                case SITE.NJUAT:
                case SITE.NJPROD:
                case SITE.LOCAL:
                    return "RANK";
                case SITE.NYDEMO:
                case SITE.NYPROD:
                case SITE.NYLOCAL:
                    return "POSITION";
                default:
                    return ""
            }

        default:
            return "";
    }
}