import React, { useState } from "react";
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, Tab, Tooltip, Typography } from "@mui/material";
import {
    GetDailyShooting,
    RunGetDashboardRefreshToken,
    RunGetDashboardToken,
    RunGetDefaultCity
} from "../../../services/dashboard.service";
import { DashboardMarqueeTimer, DashboardRefresh, DashboardTimer, SiteName } from "../../../services/config.service";
import Marquee from "react-fast-marquee";
import { RunDarQuery } from "../../../services/getDar.service";
import { formatDT } from "../../../services/formatDate.service";
import { isUserPermission } from "../../../services/auth.service";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";
import ApplicationWithdrawn from "./_applicationsWithdrawn";
import ApplicationTransfer from "./_applicationsTransferredToInves";
import RightApplicationWithdrawn from "./_rightApplicationWithdraw";
import RightApplicationTransfer from "./_rightApplicationTrnasferToInves";
import LeftApplicationReceived from "./_apprecceived";
import RightApplicationReceived from "./_rightApplicationReceived";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Dropdown } from "primereact/dropdown";
import { report } from "process";
import { SITE } from "../../../interfaces/config.interface";
import ShipEntryBarChartMonthly from "../NJPort/ShipEntryBarChartMonthly";
import NJPortMap from "../NJPort/_njPortMap";
import PDONotHired from "../NJPort/njPDONotHired";
import VolunteerHired from "../NJPort/njVolunteerHired";
import PDOHireBarChart from "../NJPort/pdoHireBarChart";
import PDOHirePieChart from "../NJPort/pdoHirePieChart";
import PDOHireStackedBarChart from "../NJPort/pdoHireStackedBarChart";
import PDOHireStats from "../NJPort/pdoHireStats";
import TerminalHireBarChart from "../NJPort/terminalHireBarChart";
import VolunteerHirePieChart from "../NJPort/volunteerHirePieChart";
import VolunteerHireStats from "../NJPort/volunteerHireStats";
import ApplicationByRace from "./_applicationsByRace";
import ApplicationByGender from "./_applicationsByGender";

interface DashboardLayoutProps {
    GetStats: any;
    data: any,
}


const INDashboardLayout: React.FC<DashboardLayoutProps> = ({ GetStats, data }) => {
    let today = new Date()
    today.setHours(23)
    today.setMinutes(59)
    today.setSeconds(59)

    /*let monthAgoDate = new Date();
    monthAgoDate.setMonth(monthAgoDate.getMonth()-1)
    monthAgoDate.setDate(monthAgoDate.getDate())
    monthAgoDate.setHours(0)
    monthAgoDate.setMinutes(0)
    monthAgoDate.setSeconds(0)*/

    let monthAgoDate = new Date();
    monthAgoDate.setMonth(0, 1)
    monthAgoDate.setHours(0)
    monthAgoDate.setMinutes(0)
    monthAgoDate.setSeconds(0)
    monthAgoDate.setFullYear(today.getFullYear());

    const [RefreshCount, setRefreshCount] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
    const [city, setCity] = React.useState<string>("");
    const [county, setCounty] = React.useState<string>("")
    const [dateUpdate, setDateUpdate] = React.useState<boolean>(true)
    const [defaultstatus, setDefaultStatus] = React.useState<string>("");    
    const [toDate, settoDate] = React.useState<Date>(today)
    const [fromDate, setfromDate] = React.useState<Date>(monthAgoDate)
    const [shootingNot, setShootingNot] = React.useState<any[]>([{ time: "", description: "", address: "" }])
    const [cityList, setCityList] = React.useState<any[]>([])
    const [heatMapTab, setHeatMapTab] = React.useState("1");
    // const [data, setData] = useState<any>({})

    // left side usestate
    const [analysisdata, setAnalysisData] = useState<any>({});
    const [widthDrawdata, setWidthDrawData] = useState<any>({});
    const [transferdata, setTransferData] = useState<any>({})
    const [generdata, setGenderData] = useState<any>({});
    const [racedata, setRaceData] = useState<any>({})
    const [refferaldata, setRefferalData] = useState<any>({})
    const [pacissuedata, setPacissueData] = useState<any>({})
    const [collectdata, setCollectionData] = useState<any>({})
    const [duplicatedata, setDuplicateData] = useState<any>({})
    const [receivedata, setReceiveData] = useState<any>({})

    // right side API usestate
    const [rightReceivedata, setRightreceiveData] = useState<any>({})
    const [rightwidthDrawdata, setRightWidthDrawData] = useState<any>({})
    const [rightTransferdata, setRightTransferData] = useState<any>({})
    const [rightGenerdata, setRightGenerData] = useState<any>({})
    const [rightRacedata, setRightRaceData] = useState<any>({})
    const [rightRefferaldata, setRightRefferalData] = useState<any>({})
    const [rightPacissuedata, setRightPacissueData] = useState<any>({})
    const [rightCollectdata, setRightCollectData] = useState<any>({})
    const [rightDuplicatedata, setRightDuplicatedata] = useState<any>({})
    const [rightAnalysisdata, setRightAnalysisData] = useState<any>({})


    React.useEffect(() => {
        if (!initialLoad) {
            RunGetDashboardToken().then((dashboard) => {
                if (dashboard !== null && dashboard?.AuthToken) {
                    localStorage.setItem("dashboard", dashboard.AuthToken);

                    if (
                        !(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)
                    ) {
                        //TBD
                    } else {
                        //TBD
                    }
                    setLoading(false);
                    setInitialLoad(true);
                }
            });
        }
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (!loading) {
                let params = {
                    StartDate: fromDate,
                    EndDate: toDate,
                    //City: city && city.trim().length > 0 ? city : "ALL CITIES",
                    //County: county && county.trim().length > 0 ? county : "ALL COUNTIES",
                    //Agency: agency && agency.trim().length > 0 ? agency : "",
                    //StolenType: "",
                    State:
                        (SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL)
                            ? "DC"
                            : "NJ",
                };
                GetStats(params);
                if (dateUpdate) {
                    let today = new Date();
                    settoDate(today);
                }
            }
        }, DashboardTimer * 6);
        return () => clearInterval(interval);
    }, [fromDate, toDate]);

    const handleValueChange = (field: string, val: any) => {
        let temp = { ...report };
        switch (field) {
            
        }
        //setReport(temp);
    };

    const handleRefreshCount = () => {
        ////fromDate.setHours(0);
        ////fromDate.setMinutes(0);
        ////fromDate.setSeconds(0);
        ////toDate.setHours(23);
        ////toDate.setMinutes(59);
        ////toDate.setSeconds(59);       
        setRefreshCount(c => c + 1)
        HandleRefreshCount()
    }

    const HandleRefreshCount = () => {
        setRefreshCount(c => c + 1)
    }

    const handleBlur = (date: any) => {
        //handle toDate date time when user inputs date
        if (date) {
            if (compareDatesByComponents(toDate, new Date(date?.target?.value))) {
                let tempDate = new Date(date?.target?.value)
                tempDate?.setHours(23)
                tempDate?.setMinutes(59)
                tempDate?.setSeconds(59)
                settoDate(tempDate)

            }
        }
        else {
           //TBD
        }
    }

    function compareDatesByComponents(date1: Date, date2: Date): number {
        const yearDiff = date1.getFullYear() - date2.getFullYear();
        const monthDiff = date1.getMonth() - date2.getMonth();
        const dayDiff = date1.getDate() - date2.getDate();

        if (yearDiff !== 0) {
            return yearDiff;
        }

        if (monthDiff !== 0) {
            return monthDiff;
        }
        return dayDiff;
    }

    return (
        <div>
            {!loading ? (
                <div>
                    <div
                        className="text-center text-black font-bold mt-2"
                        style={{ fontSize: 26 }}
                    >
                        <span>Port Regulatory & Licensing Bureau Dashboard</span>
                    </div>
                    <div className="mt-3">
                        <div className="my-4 mx-6 grid grid-cols-12 gap-x-4 justify-items-center">
                            <div className="col-span-3" style={{ width: "100%" }}>
                                <div
                                    className="border-gray-900 border-2 h-auto"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Applications Received"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Applications Received
                                        </div>
                                    </Tooltip>
                                    <PDOHireStats HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={fromDate} EndDate={toDate} />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="PAC Cards Issued"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            PAC Cards Issued
                                        </div>
                                    </Tooltip>
                                    <PDOHireStackedBarChart HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={fromDate} EndDate={toDate} />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Pie chart of Outcome Status for Total Applicants Received"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            Outcome Status for Total Applicants Received
                                        </div>
                                    </Tooltip>
                                    <div className="">
                                        <PDOHirePieChart />
                                    </div>
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Average Processing Times by Job Type"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Average Processing Times by Job Type
                                        </div>
                                    </Tooltip>
                                    <div className="pt-4">
                                        <PDOHireBarChart EndDate={toDate} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-6" style={{ width: "100%" }}>
                                <div
                                    className="border-gray-900 border-2 mb-4 "
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Filter the dashboards data to a specific Date Range"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            Dashboard Filters
                                        </div>
                                    </Tooltip> 
                                    <div className="grid grid-cols-12 gap-4 p-2">
                                        <div className={(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? "col-span-4" : "col-span-6"}>
                                            <DatePickerComponent cssClass="e-outline" value={fromDate} onBlur={(date: any) => { compareDatesByComponents(fromDate, new Date(date?.target?.value)) ? setfromDate(new Date(date?.target?.value)) : console.log('') }} onChange={(date: any) => { date.value ? setfromDate(date.value) : setfromDate(fromDate) }} floatLabelType="Auto" placeholder="Start Date" />

                                        </div>
                                        <div className={(SiteName === SITE.DCPROD || SiteName === SITE.DCUAT || SiteName === SITE.DCDEMO || SiteName === SITE.DCLOCAL) ? "col-span-4" : "col-span-6"}>
                                            <DatePickerComponent cssClass="e-outline" value={toDate} onBlur={(date: any) => { handleBlur(date) }} onChange={(date: any) => { date.value ? settoDate(date.value) : settoDate(toDate) }} floatLabelType="Auto" placeholder="End Date" />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="border-gray-900 border-2 mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Heatmap locations are plotted on the center of the municipality boundary"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            Location Data
                                        </div>
                                    </Tooltip>
                                    {/* AVG Recovery Time */}
                                    <div className="px-2 pb-2">
                                        <NJPortMap
                                            data={data?.njPortData?.AnyTable}
                                            county={county}
                                            city={city}
                                            tab={heatMapTab}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-3 " style={{ width: "100%" }}>
                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Applications by Gender"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Applications by Gender
                                        </div>
                                    </Tooltip>
                                    <ApplicationByGender HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={fromDate} EndDate={toDate} />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto "
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="2025 Applications by Race"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg border-gray-900 border-b-2">
                                            Applications by Race
                                        </div>
                                    </Tooltip>
                                    <ApplicationByRace HandleRefreshCount={HandleRefreshCount} RefreshCount={RefreshCount} StartDate={fromDate} EndDate={toDate} />
                                </div>

                                <div
                                    className="border-gray-900 border-2 h-auto  mt-4"
                                    style={{ borderRadius: "6px" }}
                                >
                                    <Tooltip
                                        title="Male-to-Female Ratio"
                                        placement="top"
                                        style={{ fontSize: "16px" }}
                                        arrow
                                    >
                                        <div className="bg-blue-900 font-bold text-yellow-400 text-center text-lg">
                                            Male-to-Female Ratio
                                        </div>
                                    </Tooltip>
                                    <VolunteerHirePieChart />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default INDashboardLayout;
