import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TVInfo from "../../../interfaces/TVInfo.interface";
import { CircularProgress, Button, Tooltip } from "@mui/material";
import TowedVehicleIcon from "../../../assets/Images/towing1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,    
    Inject,
    Page,
    Sort,
    Filter
} from '@syncfusion/ej2-react-grids';

interface PanelTowedVehicleProps {
    person: PersonInfo,
    TVInfos: TVInfo[],
    isLoading: boolean
}

const PanelTowedVehicle: React.FC<PanelTowedVehicleProps> = ({ person, TVInfos, isLoading})=> {
    //Towed Vehicle
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        if (TVInfos) {
            let tempP: any = [...TVInfos]
            for (let p of tempP) {
                if (p.red_TimeStamp) {
                    p.red_TimeStamp = new Date(p.red_TimeStamp)
                }
            }
            setFormatData(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [TVInfos])    

    return(
        <div className={"m-5 border"}>
            <Disclosure defaultOpen={false}>
                {({ open }) => (
                    <>
                        {/*<Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left" onClick={() => { ALPRTrigger() }}>*/}
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    {/*<span className={"font-bold"}>ALPR <img src={ALPRIcon} alt={''} style={{ display: 'inline' }} /> (Click here to get Latest ALPR)  
                                        {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} />  : <></>}</span>*/}

                                    <span className={"font-bold"}>Towed Vehicle <img src={TowedVehicleIcon} alt={''} style={{ display: 'inline' }} />                                       
                                        {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "10px" }} /> : <></>}
                                    </span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()}
                                    &nbsp;&nbsp;Records: {TVInfos?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">
                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>                                    
                                                                    
                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    )
};
export default PanelTowedVehicle;