import { TSC, TSCFiles } from "../interfaces/TSC.interface";
import { AxiosResponse } from "axios";
import { PersonInfo, getDarInfoResponse } from "../interfaces/getDarInfo_interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import AuthenticatedAPIReq from "./AuthenticatedAPIReq.service";
import { FileData } from "../interfaces/GunOffender.interface";

export async function SetGunOffender(record: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/GunOffender/SetGunOffender', record)
    return (res.data)
}

export async function SetGunOffenderFile(fileObj: FileData): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/GunOffender/SetGunOffenderFile', fileObj)
    return (res.data)
}

export async function GetGunOffender(id: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/GunOffender/GetGunOffender?IDs=' + id)
    return (res.data)
}

export async function GetGunOffenderNoSplash(id: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReq.get('/api/GunOffender/GetGunOffender?IDs=' + id)
    return (res.data)
}

export async function SearchGunOffender(searchObj: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/GunOffender/SearchGunOffender', searchObj)
    return (res.data)
}

export async function GetDistrictPsa(address: any): Promise<any> {
    let res: AxiosResponse<any> = await AuthenticatedAPIReq.get('/api/GunOffender/GetDistrictPsa?address=' + address)
    return (res.data)
}

export function GetGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}