import React from 'react';
import { DateTimePicker, LocalizationProvider, TabContext, TabList, TabPanel } from '@mui/lab';
import { PostAdd, Close, FormatListBulleted, ExpandMore, PictureAsPdf } from '@mui/icons-material';
import { Box, FormControl, FormControlLabel, FormLabel, Tab, Tooltip, Snackbar, Alert, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, Button, TextField, Typography, DialogActions, Container, Checkbox, RadioGroup, Radio, DialogContentText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import {
    GunOffender
} from "../../../interfaces/GunOffender.interface";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GunOffenderInputForm from './_gunOffenderForm';
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search,
    ExcelExportProperties,
    Resize,
    Reorder,
    SortSettingsModel,
    CommandColumn,
    setChecked
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { formatDT, formatDate } from '../../../services/formatDate.service';
import { SetGunOffender } from '../../../services/gunoffender.service';
import { useLocation } from "react-router-dom";
import { JAOS } from '../../../services/JAOS.service';

interface GunOffenderSearchResultsProps {
    Records: GunOffender[],
    RefreshTrigger: any
}

const GunOffenderSearchResults: React.FC<GunOffenderSearchResultsProps> = ({ Records, RefreshTrigger }) => {

    const [tabValue, setTabValue] = React.useState('2');
    const [tabModLabelValue, setTabModLabelValue] = React.useState('CREATE NEW');
    const [editingRecord, setEditingRecord] = React.useState<null>(null);
    const [typedRecords, setTypedRecords] = React.useState<any[]>([])
    const [personDetails, setPersonDetails] = React.useState<null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [submitted, setSubmitted] = React.useState<boolean>(false)

    let [darTbl, setDarTbl] = React.useState<any>(null)

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    //Looks in URL for person object and sets darTbl to that object, which then gets sent to input form as prop
    const checkPersonData = () => {
        if (!submitted) {
            let person = query.get("person");
            let data: any;
            if (person) {
                let l = new JAOS()
                data = l.objFromStack(person);
            }
            if (data) {
                setDarTbl(data); // Set state
                setTabValue('1');
            }
        }
    }

    let grid: Grid | null;

    //Sets the grid to all fetched records
    const handleDataTyping = async () => {
        if (Records) {
            let tempR: any = [...Records]
            setIsLoading(true)
            await new Promise(r => setTimeout(r, 1000)); //Why I need to sleep... This fixes the syncfusion no rerender on my machine
            setTypedRecords(tempR)
            setIsLoading(false)
        }
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text == "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Gun_Offender.xlsx';

            grid.excelExport(excelProp);
        }
    }

    const handleEditGunOffender = (rowData: any) => {
        if (rowData && rowData.ID) {
            setEditingRecord(rowData?.ID)
            handleTabStateChange('1', true)
        }

    };

    const handleDeleteGunOffender = (rowData: any) => {
        if (window.confirm("Are you sure you would like to delete this Gun Offender entry?")) {
            let temp = { ...rowData }
            temp.deleted = true
            SetGunOffender(temp)
            if (grid) {
                // @ts-ignore
                let dSource: any = [...grid?.dataSource]
                if (temp.ID && temp.ID != 0) {
                    let index: number = dSource.findIndex((e: any) => e.ID === temp.ID)
                    if (index >= 0) {
                        dSource.splice(index, 1)
                        grid.dataSource = dSource
                        setTypedRecords(dSource)
                        grid?.refresh()
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        handleDataTyping()
        checkPersonData()
    }, [Records])

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        handleTabStateChange(newValue)
    }

    const actionTemplate = (rowData: any) => {
        return (
            <ButtonGroup>
                <Tooltip title="Edit Gun Offender" placement="top" arrow>
                    <IconButton onClick={() => handleEditGunOffender(rowData)}>
                        <EditIcon fontSize='small' color='primary' />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete Gun Offender" placement="top" arrow>
                    <IconButton onClick={() => handleDeleteGunOffender(rowData)}>
                        <DeleteIcon fontSize='small' color='error' />
                    </IconButton>
                </Tooltip>

            </ButtonGroup>
        );

    };

    const handleFormSubmit = (formData: any) => {
        setDarTbl(null)
        setSubmitted(true)
        let q_person = query.get("person");
        if (q_person) {
            let l = new JAOS()
            l.delFromStack(q_person);
        }
        if (formData)
            RefreshTrigger()

        setEditingRecord(null)
        handleTabStateChange('2')
    };

    const handleTabStateChange = (tab: any, edit: boolean = false) => {
        if (tab === '2') {
            setTabModLabelValue('CREATE NEW')
            setEditingRecord(null)
        } else if (tab === '1') {
            if (!edit) {
                setEditingRecord(null)
                setTabModLabelValue('CREATE NEW')
            } else {
                setTabModLabelValue('EDIT')
            }
        }

        setTabValue(tab)
    }

    return (
        <div className="m-5 p-5">
            <Box className="" style={{ justifyContent: 'center' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                            <Tab icon={<FormatListBulleted />} label="LIST VIEW" value={'2'} />
                            <Tab icon={<PostAdd />} label={`${tabModLabelValue} ENTRY`} value={'1'} />
                        </TabList>
                    </Box>
                    <TabPanel value='2'>
                        {/*<Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>*/}
                        {/*    <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%', height: '25%', fontSize: '1.2rem' }}>*/}
                        {/*        {alertText}*/}
                        {/*    </Alert>*/}
                        {/*</Snackbar>*/}
                        <div className={"pl-10 pr-10 mt-5"}>
                            <h4 className={"font-bold pl-5"}>
                                {typedRecords?.length} Records Found</h4>

                            {!isLoading && typedRecords && typedRecords.length > 0 &&
                                <GridComponent
                                    dataSource={typedRecords}
                                    allowPaging={true}
                                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                                    allowSorting={true}
                                    allowMultiSorting={true}
                                    allowFiltering={true}
                                    filterSettings={{ type: 'CheckBox' }}
                                    allowTextWrap={true}
                                    textWrapSettings={{ wrapMode: 'Both' }}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbar={['ExcelExport', 'Search']}
                                    toolbarClick={GridToolsClick}
                                    ref={(g) => { if (g) { grid = g } }}
                                    allowSelection={true}
                                    allowResizing={true}
                                    rowHeight={24}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective field='CreateDate' visible={true} width={140} template={(r: any) => (formatDT(r.CreateDate))} minWidth={140} maxWidth={140} headerText='Report Created' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='FirstName' width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='LastName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='DOB' allowSorting={false} template={(r: any) => (r.DOB)} width={140} minWidth={140} maxWidth={140} headerText='DOB' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='PDID' width={140} minWidth={140} maxWidth={140} headerText='PDID' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective field='OffenderAddress' width={120} minWidth={120} maxWidth={120} headerText='Address' customAttributes={{ class: ['e-attr'] }} />
                                        <ColumnDirective template={actionTemplate} headerText="Actions" width="135" textAlign="Center" />
                                    </ColumnsDirective>
                                    <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder, CommandColumn]} />
                                </GridComponent>
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value='1'>
                        {tabValue === '1' && <GunOffenderInputForm handleReportSubmit={handleFormSubmit} editForm={editingRecord} gunOffender={darTbl} />}
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}

export default GunOffenderSearchResults;