import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";


interface ApplicationByRaceProps {
    HandleRefreshCount: any
    RefreshCount: number
    StartDate: Date,
    EndDate: Date
}

const ApplicationByRace: React.FC<ApplicationByRaceProps> = ({
    HandleRefreshCount,
    RefreshCount,
    StartDate,
    EndDate
}) => {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const payload = {
            queryType: "INVESTIGATION_RACE_YEAR5",
            dateTypeValue: 1,
            FromDate: StartDate,
            ToDate: EndDate,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setData(qRes?.AnyTable);
        });
    }, [RefreshCount, StartDate, EndDate]);

    const formattedData = (maindata: any) => {
        let groupdData: any = [];
        maindata.forEach((item: any) => {
            groupdData.push({ name: item.SubGroup_Desc, value: item.Total });
        });
        //console.log('formattedData 2', { groupdData });
        return groupdData;
    }

    const handleFormatXAxis = (maindata: any) => {
        let xAxis: any = []
        maindata.forEach((item: any) => {
            xAxis.push(item.SubGroup_Desc);
        });
        //console.log('handleFormatXAxis 2', { xAxis });
        return xAxis;
    }

    const handleSeries = () => {
        return [
            {
                type: 'bar',
                color: '#1e3a8a',
                emphasis: {
                    focus: 'series'
                },
                label: {
                    show: true,
                    position: 'inside',
                    rotate: 20,
                    fontSize: 10,
                    formatter: '{b}:{@Type}',
                },
                data: formattedData(data),
            },
        ];
    };

    const option = {
        minheight: "200",
        width: "95%",
        toolbox: {
            feature: {
                saveAsImage: { show: true },
            },
        },
        grid: {
            left: "1%",
            right: "1%",
            bottom: "1%",
            top: "3%",
            containLabel: true,
        },
        legend: {},
        yAxis: {
            type: "value",
        },
        xAxis: {
            type: "category",
            axisLabel: {
                interval: 0,
                rotate: 45,
                fontSize: 8,
            },
            data: handleFormatXAxis(data),
        },
        series: handleSeries()
    };

    return (
        <>
            {data && data.length !== 0 ? (
                <div>
                    <ReactECharts option={option} style={{ height: "400px" }} />
                </div>
            ) : (
                <div
                    className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                    style={{ color: "red", fontWeight: 500 }}
                >
                    No Data
                </div>
            )}
        </>
    );
};

export default ApplicationByRace;